import React, { useState } from "react";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton, Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "../Components/Badge";

const portfolioItems = [
  {
    title: "Song Quiz",
    description:
      "A voice-activated trivia game where players guess the title and artist of songs.",
    screenshot: "/song_quiz.png",
    technologies: ["Typescript", "React"],
  },
  {
    title: "Podio",
    description:
      "A web application that plays the latest episodes of select NPR podcasts.",
    screenshot: "/podio.png",
    github: "https://github.com/cocomittens/Pod.io",
    technologies: ["Typescript", "React"],
  },
  {
    title: "Adaptify",
    description: "Adaptive user interface based on human emotion.",
    screenshot: "/adaptify.png",
    github: "https://github.com/cocomittens/adaptify",
    technologies: ["Django", "Supabase", "Next.js"],
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
};

function PortfolioImage(props) {
  return (
    <CardMedia
      sx={{ height: 250 }}
      image={props.url || "https://via.placeholder.com/250"}
      title="Portfolio Item"
    />
  );
}

const PortfolioModal = ({ open, handleClose, item }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ width: { xs: "100%", md: "50%" }, pr: { md: 2 } }}>
          <img
            src={item.screenshot}
            alt={item.title}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" }, mt: { xs: 2, md: 0 } }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {item.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {item.description}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {item.github && (
              <Link
                href={item.github}
                target="_blank"
                rel="noopener"
                sx={{ mr: 2 }}
              >
                GitHub
              </Link>
            )}
            {item.liveDemo && (
              <Link href={item.liveDemo} target="_blank" rel="noopener">
                Live Demo
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default function Portfolio() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  return (
    <Box bgcolor="#eee" py={4} id="portfolio">
      <Container maxWidth="lg">
        <Typography
          id="portfolio"
          align="center"
          variant="h3"
          color="#222"
          gutterBottom
          pt={1}
        >
          Portfolio
        </Typography>

        <Grid container spacing={4} justifyContent="center" pb={4}>
          {portfolioItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                onClick={() => handleOpen(item)}
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                    cursor: "pointer",
                  },
                }}
              >
                <PortfolioImage url={item.screenshot} />
                <CardContent>
                  <Typography variant="h5" color="#222">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="#222">
                    {item.description}
                  </Typography>
                  <CardActions>
                    {item.technologies.map((tech, i) => (
                      <Badge name={tech} key={i} alternate={i % 2 === 0} />
                    ))}
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {selectedItem && (
        <PortfolioModal
          open={open}
          handleClose={handleClose}
          item={selectedItem}
        />
      )}
    </Box>
  );
}
