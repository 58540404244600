import React from "react";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import DarkModeIcon from "@mui/icons-material/DarkMode";

export default function NavBar() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBar position="fixed" sx={{ background: "#222" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              mr: 2,
              alignItems: "center",
            }}
          >
            <DarkModeIcon sx={{ mr: 1, color: "#fff" }} />
            <Typography variant="h6" color="#fff">
              Corrie Stoddard
            </Typography>
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="About" href="#about" />
            <Tab label="Experience" href="#experience" />
            <Tab label="Portfolio" href="#portfolio" />
            <Tab label="Contact" href="#contact" />
          </Tabs>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
